@import '../../colors.scss';

.route-link {
  position: relative;

  .map-space {
    display: inline-block;
    position: relative;
    width: 40px;
  }

  .bottom-line {
    color: $color-408aff;
    font-size: 13px;
    font-weight: bold;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: solid 1px #979797;
  }
}

.big-route-link {
  .bottom-line {
    width: 180px;
    display: block;
    margin-left: 40px;
    font-size: 10px;
  }

  .date-from {
    position: absolute;
    left: 8px;
  }

  .date-to, .to {
    position: absolute;
    right: -50px;
    top: 0;
  }

  .location-icon-wrapper {
    position: absolute;
    top: -11px;
  }

  .icon.framelessMapIcon {
    height: 30px;
  }

  .map-icon-wrapper {
    position: absolute;
    top: -65px;
    left: 220px;

    .map-icon {
      height: 48px;
      cursor: pointer;
    }
  }

  .edge-icon {
    position: absolute;

    &.from {
      left: -5px;
    }

    &.to {
      right: -64px;
    }
  }
}
