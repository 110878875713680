@import '../colors.scss';

.settings-page {
  background-color: white;
  width: 964px;

  .settings-header {
    padding: 30px;
    width: 904px;
    height: 117px;
    background-image: linear-gradient(258deg, #ffd368, #fd9b66);

    .header-title {
      text-align: center;
      font-size: 35px;
      letter-spacing: -0.72px;
      color: white;
    }

    .progress-bar {
      position: relative;
      width: 358px;
      margin: auto;
      height: 10px;
      margin-top: 15px;

      .step-circle {
        position: absolute;
        top: 0;
        border-radius: 60px;
        background-color: white;
        width: 58px;
        height: 58px;
        text-align: center;
        font-size: 30px;
        letter-spacing: -0.61px;
        color: $light-orange;
        line-height: 1.8;

        &.step-1 {
          left: 0;
        }

        &.step-2 {
          left: 150px;
        }

        &.step-3 {
          left: 300px;
        }

        &.current-step {
          font-size: 40px;
          font-weight: bold;
          letter-spacing: -0.82px;
          color: $orange;
          line-height: 1.4;
        }
      }

      .delimiter {
        width: 85px;
        height: 1px;
        border-bottom: 1px white solid;
        position: absolute;
        top: 30px;

        &.delimiter-1 {
          left: 61px;
        }

        &.delimiter-2 {
          left: 211px;
        }
      }
    }
  }

  .settings-step-content {
    width: 964px;
    border-bottom: 1px solid $color-f4f8f9;
  }

  .section-title {
    margin-bottom: 24px;
  }

  .settings-account-page {
    .start-planning {
      vertical-align: top;
    }

    .work-hours-display-wrapper {
      display: inline-block;
    }

    .sro-input {
      &.account-name {
        vertical-align: top;
      }

      input {
        width: 178px;
      }
    }

    .section-box {
      padding: 30px 0 30px 69px;
    }

    .work-hours-display {
      margin-left: 28px;
    }

    .agent-defaults-section,
    .agent-score-section {
      border-top: 1px solid $color-b8c1c8;

      h3 {
        font-size: 14px;
        font-weight: normal;
      }

      .agent-kpi-input {
        width: 165px;

        input {
          width: 120px;
        }
      }
    }

    .visit-time {
      margin-right: 0;
      vertical-align: top;
    }

    .agent-score-total-display {
      line-height: 2;
      padding: 0 10px;
      margin-top: 10px;
      background-color: $super-lightgray;
      font-size: 11px;
      color: $green-gray;
      display: inline-block;
    }
  }

  .add-work-hours {
    float: left;

    &.sro-button.sro-button-white:hover {
      background-color: $super-lightgray;
    }
  }

  .work-hours-section {
    padding-top: 25px;

    button.sro-button span {
      color: $green-gray;
    }
  }

  .work-hours-display {
    line-height: 2;
    padding-left: 16px;
    float: left;
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: no-wrap;
    overflow: hidden;

    width: 227px;
    height: 24px;
    background-color: $super-lightgray;
    font-size: 11px;
    color: $green-gray;
  }

  .client-settings {
    padding: 22px 48px;

    .sro-dropdown {
      width: 237px;
    }
  }

  .value-field {
    margin-right: 0;
  }

  .global-buttons {
    padding-top: 33px;
  }

  .settings-footer {
    display: block;
    padding: 16px 39px;
    width: 886px;
    height: 45px;

    .sro-button {
      float: right;
    }

    .error-message {
      float: left;
      color: red;
      font-size: 12px;
      line-height: 4;
    }
  }

  .agent-settings {
    .empty-values-comment {
      color: gray;
      font-size: 12px;
      margin: 0 0 17px 36px;
    }

    th {
      background-color: $super-lightgray;
      font-size: 12px;
      color: $navy;
      padding: 0 5px;
      height: 36px;
      font-weight: 300;
    }

    td {
      font-size: 11px;
      color: $green-gray;
      text-align: center;
    }

    .email-col {
      width: 100px;
      text-align: left;

      .sro-input,
      input {
        padding-left: 0;
        width: 100px;
        margin-right: 0;
        text-align: center;
      }
    }

    .visit-time-minutes,
    .max-dist-input,
    .daily-visits,
    .max-daily-phone-calls {
      width: 50px;
      margin-right: 0;

      input {
        padding-left: 0;
        text-align: center;
      }
    }

    .section-title {
      margin: 17px 36px;
    }

    .sro-geopicker-wrapper {
      width: 160px;
      margin-right: 0;

      .sro-input.sro-geoinput {
        width: 100%;
      }
    }
  }

  .client-rating-summary-modal-wrapper {
    position: absolute;
    top: 30px;
    left: 50%;
    z-index: 20;
    width: 1;
    height: 100%;
    padding-top: 30px;

    .initial-loading {
      position: absolute;
      top: 300px;
      left: 350px;
    }

    .client-rating-summary-modal {
      $width: 739px;

      position: absolute;
      top: 50px;
      left: -$width / 2;

      height: 667px;
      width: $width;
      background-color: white;

      border-radius: 6px;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.19);
    }

    .table-header {
      margin-left: 41px;
    }
  }

  .full-screen-cover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    background-color: rgba(62, 62, 62, 0.48);
    width: calc(100% - 10px);
    height: calc(100%);

    .sub-modal {
      $width: 568px;

      position: absolute;
      top: 200px;
      left: 50%;

      font-size: 22px;
      text-align: center;
      color: $color-8a8a8a;

      .icon.x {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 100;
        width: 11px;
      }

      .submodal-content {
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.19);
        background-color: white;
        width: $width;
        padding: 50px 0;

        position: absolute;
        top: 0;
        left: -$width / 2;
        border-radius: 4px;

        .success-text {
          margin: auto;
          width: 300px;
        }

        .in-progress-text {
          margin: auto;

          margin-bottom: 17px;
          width: 355px;
        }

        .successV {
          height: 55px;
        }

        &.submodal-errors {
          font-size: 15px;
          color: $peach;
          text-align: center;
        }
      }
    }
  }

  .error-line {
    // Overrides the default tooltip behavior, otherwise it shows in the wrong position
    display: block !important;
    width: 550px;

    .sro-tooltip {
      max-width: 400px;
      white-space: pre-wrap;
      text-align: left;
    }

    .text {
      line-height: 3.5;
      color: red;
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-height: 30px;
      max-width: 550px;
    }
  }

  .back-button {
    margin-right: 20px;

    span {
      color: $extra-lightblue;
    }
  }
}
