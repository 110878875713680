@import '../../colors.scss';


.trip-shadow {
    $filler-color: $super-lightgray;
    $table-width: 371px;

    .table-pane {
        background-color: $white;
        width: $table-width;
        height: 100vh;
        float: left;

        .table-items {
            margin-top: 30px;
        }

        .table-line {
            margin: 16px;
            width: 350px;
            height: 3px;
            background-color: $filler-color;
        }
    }

    .map-pane {
        width: calc(100% - #{$table-width});
        height: calc(100vh - 50%);
        float: left;

        .loading-map-text {
            margin-top: 25%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.41px;
            color: $darkgray;
        }
    }
}
