@import '../../colors.scss';

.sro-lightweight-tooltip-anchor {
  $color: $color-011e42;

  position: relative;
  display: inline-block;

  &.display-block {
    display: block;
  }

  .sro-lightweight-tooltip {
    position: absolute;
    padding: 7px;
    border-radius: 3px;
    z-index: 10;
    font-size: 13px;
    font-weight: normal;

    background-color: $color;
    color: $white;
    display: none;
    text-align: center;

    .triangle {
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background-color: $color;
    }
  }

  &:hover {
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      left: 50%;
      transform: translateX(-50%);
    }

    .sro-lightweight-tooltip {
      display: block;
    }
  }
}
