$placeholder-color: #c1c3c5;
$background-color: #fafbfd;
$button-border: #b8c1c8;
$button-border-hover: #a4adb4;

$modal_border_color: #b8c1c8;

$super-darkgray: #282c34;
$darkgray: #323c47;
$middle-gray: #888;
$super-lightgray: #f5f5f5;
$fb-lightgray: #fbfbfb;
$extra-lightgray: #e0e0e0;
$white: #ffffff;
$black: #000000;
$lightblue: #4a90e2;
$extra-lightblue: #5abff5;
$middle-blue: #4da1ff;
$cyan: #61dafb;
$blue: #63a1ff;
$shiny-green: #5ee8a3;
$shiny-orange: #ff7151;
$orange: #ff9900;
$light-orange: #ffc700;
$green-gray: #8f9091;
$offwhite: #ffecec;
$navy: #3e4c5c;
$shiny-blue: #628CF8;
$gray5: #535353;
$offgreen: #939fab;
$peach: #ff4b4e;

$color-011e42: #011e42;
$color-0c59ff: #0c59ff;
$color-2888d0: #2888d0;
$color-354052: #354052;
$color-408aff: #408aff;
$color-485465: #485465;
$color-4a4a4a: #4a4a4a;
$color-5788cb: #5788cb;
$color-5e5e5e: #5e5e5e;
$color-7ed321: #7ed321;
$color-7fbcfa: #7fbcfa;
$color-8a8a8a: #8a8a8a;
$color-8f9bad: #8f9bad;
$color-969696: #969696;
$color-989898: #989898;
$color-999999: #999999;
$color-9f9f9f: #9f9f9f;
$color-b8c1c8: #b8c1c8;
$color-c1c3c5: #c1c3c5;
$color-c3c2c2: #c3c2c2;
$color-ccc: #ccc;
$color-e8e8e8: #e8e8e8;
$color-e8f3fc: #e8f3fc;
$color-ebeff4: #ebeff4;
$color-eee: #eee;
$color-f4f8f9: #f4f8f9;
$color-f5f8f9: #f5f8f9;
$color-f6f8f9: #f6f8f9;
$color-fbfcfd: #fbfcfd;
$color-fbfcfd: #fbfcfd;
$color-fee: #fee;
$color-e9eff4: #e9eff4;
$color-a4adb4: #a4adb4;

$hover_highlight_color: mix($offwhite, #000, $weight: 90%);
$modal_bg: $white;
$modal_title_color: $green-gray;
$no_info_color: $green-gray;
$order-drilldown-colored-line: $offwhite;
