@import '../../colors.scss';

.work-hours-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 100;
  width: 1px;
  height: 1px;
  text-align: left;

  .work-hours-modal {
    $width: 514px;

    position: absolute;
    top: 70px;
    left: -($width / 2);
    width: $width;
    height: 487px;

    border-radius: 6px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    padding: 30px 40px 30px 30px;
  }

  .add-day-panel {
    margin-top: 33px;
    height: 78px;

    > div {
      float: left;
    }

    .add-day-title {
      padding: 15px 45px 0 0;
    }
  }

  .days-wrapper {
    height: 308px;
  }

  .days {
    clear: both;

    width: 510px;
    border: solid 1px #e5e9ec;
    border-width: 1px 1px 0 1px;

    .day-line {
      position: relative;
      border-bottom: 1px #e5e9ec solid;
      height: 33px;
      padding: 10px 0 0 40px;

      .day-name {
        width: 100px;
      }

      .error-message {
        width: 200px;
        font-size: 11px;
        color: $peach;
      }

      .remove-day-button {
        position: absolute;
        top: 15px;
        left: 15px;
      }

      .day-name,
      .error-message,
      .time-dropdown-wrapper {
        display: inline-block;
      }

      .time-dropdown-wrapper {
        position: relative;
        margin-top: 16px;

        .from,
        .to {
          position: absolute;
          top: -21px;
          left: 0;

          &.to {
            left: 85px;
          }
        }
      }
    }
  }

  .buttons {
    margin-top: 15px;

    .cancel-button {
      span {
        color: $extra-lightblue;
      }
    }

    .save-button,
    .cancel-button {
      width: 128px;
      float: right;
      margin-left: 20px;
    }
  }

  td {
    text-align: left;
  }
}
