@import '../colors.scss';

.client-rating-summary {
  .agent-name,
  .agent-chart-value {
    display: inline-block;
  }

  .has-error {
    .total {
      color: red;
    }
  }

  .agent-list {
    max-height: 130px;
    overflow: auto;
  }

  .agent-chart-value {
    padding-top: 5px;
  }

  .agent-name {
    width: 100px;
    vertical-align: top;
    line-height: 3;
  }

  .agent-name,
  .legend-item {
    font-size: 11px;
    letter-spacing: 0.12px;
    color: $color-485465;
  }

  .agent-stats-line {
    width: 100%;
    border-style: solid;
    border-width: 0 0 0.6px 0;
    border-image-source: radial-gradient(circle at 50% 0, #f5f7f8, #e7ebef);
    border-image-slice: 1;
  }

  .legend {
    text-align: right;

    .legend-item {
      display: inline-block;
      margin-left: 35px;
    }

    .item-label {
      display: inline-block;
      margin-left: 5px;
    }

    .color-sample {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
  }

  .graph-box {
    padding: 46px 41px;
    overflow: auto;
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;
    height: 270px;

    table {
      min-width: 100%;

      th {
        height: 45px;
        background-color: $super-lightgray;
        font-size: 12px;
        color: $navy;
      }

      td {
        font-size: 11px;
        color: $green-gray;
        text-align: center;
      }
    }
  }

  .buttons-section {
    position: absolute;
    bottom: 18px;
    right: 23px;
    width: 700px;

    .sro-button {
      width: 143px;
      float: right;
      margin-left: 20px;
    }
  }

  .x {
    position: absolute;
    top: 11px;
    right: 9px;
    height: 9px;
    width: 11px;

    cursor: pointer;
  }

  .error-message {
    color: red;
    font-size: 12px;
    float: left;
    line-height: 4;
  }

  .icon.loading-gif {
    position: absolute;
    bottom: 30px;
    right: 80px;
    width: 30px;
    height: 30px;
  }

  .percentage-input .percent input {
    padding-left: 0;
  }
}
