.sro-button {
  .button-svg {
    cursor: pointer;
  }

  .add-button {
    .background {
      fill: #ff7151;
    }

    &:hover .background {
      fill: #ff886d;
    }
  }

  .export-button {
    .background {
      fill: #9fda64;
    }

    &:hover .background {
      fill: #b9f181;
    }
  }

  .print-button {
    .background {
      fill: #ffb547;
    }

    &:hover .background {
      fill: #ffc46d;
    }
  }

  .reload-button {
    .background {
      fill: #4dbcff;
    }

    &:hover .background {
      fill: #7bcdff;
    }
  }
}
