@import '../../colors.scss';

.scroll-buttons {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 2;

  .scroll-forward {
    float: right;
  }

  .scroll-backward {
    float: left;
  }

  .scroll-button {
    background-color: transparent;
    border-width: 0;
    color: $color-485465;
    font-weight: normal;
    font-size: 18px;
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    padding: 0;
    cursor: pointer;
  }

  .scroll-button:hover {
    background-color: transparent;
  }
}
