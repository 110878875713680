@import '../../colors.scss';

.sro-tabset {
    margin-top: 1px;

    .tab-headers {
        padding-left: 20px;
    }

    .tab-header {
        font-size: 15px;
        color: $darkgray;
        border-bottom: 4px solid transparent;
        display: inline-block;
        margin: 0 80px 0 0;
        padding-bottom: 10px;
        cursor: pointer;

        &.active {
            border-color: $color-2888d0
        }
    }

    .sro-tabset-tab {
        display: none;
        padding-top: 20px;

        &.show {
            display: block;
        }
    }
}