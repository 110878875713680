@import './colors.scss';

.sro-sidebar {
  padding: 0;

  &.hide {
    .fill-slidebar,
    > div,
    & {
      width: 0;
      display: none;
    }
  }

  .fill-sidebar {
    background-color: $color-5788cb;
    position: fixed;
    top: 0;
    left: 0;

    width: 54px;
    height: 100vh;
    z-index: -1;

    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0m;
  }

  > div {
    overflow-y: visible;
    overflow-x: visible;
    margin: 0;
    padding: 0;

    width: 54px !important;

    > div {
      position: inherit;
    }
  }

  .menu-items {
    overflow: hidden;
  }

  &.open {
    > div,
    .fill-sidebar {
      width: 220px !important;
    }

    .collapse-wrapper {
      svg {
        transform: rotate(180deg);
      }

      path,
      rect {
        stroke: $white;
      }
    }
  }

  .collapse-wrapper {
    margin-top: 5px;
    cursor: pointer;
    height: 45px;

    path,
    rect {
      stroke: #cbcbcb;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      path,
      rect {
        stroke: $white;
      }
    }

    svg {
      margin: 12px 15px;
    }
  }

  .menu-sidebar {
    height: 100vh;
    margin: 0;
    background-color: $color-5788cb;

    a {
      color: $color-ccc;
    }
  }

  a {
    &[class*='RaMenuItemLink-active'],
    &:hover {
      color: white;

      path {
        fill: $white;
      }
    }

    path {
      fill: #cacbcb;
    }
  }
}
