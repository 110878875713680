@import '../../colors.scss';

.step1-shadow {
    margin-top:34px;
    $filler-color: $super-lightgray;

    .form-line {
        height: 100px;
    }

    .form-title {
        margin-left: 51px;
        width: 150px;
        height: 9px;
        background-color: $filler-color;
        margin-bottom: 23px;
    }

    .big-form-field, .small-form-field {
        display: inline-block;
        margin-left: 51px;

        .field-title {
            width: 107px;
            height: 9px;
            margin-bottom: 9px;
            background-color: $filler-color;
        }

        .field-input {
            width: 242px;
            height: 34px;
            background-color: $filler-color;
            margin-bottom: 33px;
        }

        &.small-form-field .field-input {
            width: 150px;
        }
    }

    .form-line.line-2 {
        margin-bottom: 61px;
    }

    .horizontal-line {
        width: 100%;
        height: 2px;
        background-color: $filler-color;
        margin-bottom: 29px;
    }
}
