@import '../../colors.scss';

.work-hours-picker {
  .work-hours-display-wrapper {
    display: inline-block;
  }

  .work-hours-display {
    text-align: left;
    cursor: pointer;
  }

  .cancel-button {
    float: right;
  }

  .hidden-value {
    display: none;
  }

  .sro-form-input {
    position: relative;
    width: 243px;
    height: 45px;
    border-radius: 4px;
    border: solid 1px $color-b8c1c8;
    margin-top: 20px;

    .clock-button {
      position: absolute;
      top: -1px;
      right: -1px;

      border: 1px $color-ebeff4 solid;
      border-radius: 0 0 4px 4px;
      background-color: $color-fbfcfd;
      width: 45px;
      height: 45px;

      .icon.clock {
        position: absolute;
        top: 10px;
        left: 12px;
        width: 24px;
        height: 24px;
      }
    }

    .text-pane {
        padding-left: 20px;
        background-color: 'red';
        overflow: hidden;
        line-height: 2.8;
    }
  }
}

.sro-form .work-hours-form-picker {
  margin-bottom: 20px;

  .hidden-text-field {
    display: none
  }

  .work-hours-input-frame {
    position: relative;

    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark-color(black, white);
    text-transform: none;
    text-indent: 0px;
    text-align: start;
    -webkit-appearance: textfield;
    margin: 0em;
    font-weight: 400;
    font-size: 13px;
    padding: 0px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;

    border-radius: 4px;
    border: 1px $color-b8c1c8 solid;
    height: 32px;
    padding-left: 15px;
    width: 240px;
    height: 47px;
    margin-top: 20px;
    margin-bottom: 6px;
    line-height: 3.7;
    overflow: hidden;

    .right-button {
      position: absolute;
      top: -1px;
      right: -1px;
      padding: 7px 0 0 10px;
      width: 35px;
      border: 1px $color-b8c1c8 solid;
      background-color: $color-fbfcfd;

      .icon.clock {
        width: 25px;
      }
    }
  }

  .work-hours-input-frame {
    margin-top: 3px;
  }
}
