@import '../../colors.scss';

.time-dropdown {
  $bgColor: $super-lightgray;
  text-align: left;

  position: relative;
  $width: 60px;
  height: 20px;

  width: 74px;
  background-color: $bgColor;

  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: normal;
  color: $darkgray;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .option,
  .selected-item {
    width: $width;
    height: 30px;
    line-height: 2.5;
    padding-left: 12px;
    background-color: $bgColor;
  }

  .toggle-wrapper {
    border-left-width: 0;
    background-color: $bgColor;
    width: 25px;
    height: 30px;
  }

  &.open {
    .option {
      width: $width - 15px;
    }
  }
}
