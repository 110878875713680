@import '../colors.scss';

.account-form {
  .create-account {
    button[type='submit'] {
      width: 100px;
      height: 45px;
      border-radius: 4px;
      border: solid 1px #d0d0d0;
      background-color: $shiny-blue;
      font-size: 13px;
      line-height: 2;
      letter-spacing: normal;
      text-transform: none;

      svg {
        display: none;
      }
    }
  }
}
