.sro-pie {
  .pie-tooltip {
    position: relative;
    background-color: black;
    color: white;
    border-radius: 4px;

    opacity: 0.6;
    line-height: 3;
    padding: 0 10px;
  }

  .recharts-legend-wrapper {
    .recharts-legend-item-text {
      margin-right: 4px;
      font-size: 11px;
    }
  }
}
