@import '../colors.scss';

div.resource-trip-map {
  padding: 0;
}

.trip-map-page {
  position: relative;
  width: 100%;
  height: 100%;

  .close-map {
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 5;
    font-size: 20px;

    path {
      stroke: $darkgray;
    }

    cursor: pointer;
    color: $darkgray;
  }

  .sro-map {
    width: 100%;
    height: 100%;
  }

  .centralize-client-button {
    cursor: pointer;
  }

  .trip-map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    font-size: 9px;
    line-height: 1.78;
    color: white;
  }

  .table-wrapper {
    $table-width: 500px;

    height: 100%;
    overflow-y: auto;

    table {
      width: $table-width;
    }
  }

  .screen-darkener {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.7;

    display: none; // overridden in the mobile
  }

  @media screen and (max-width: 700px) {
    .screen-darkener {
      display: block;
    }

    div.trip-table-section {
      top: auto;
      bottom: 0px;
      height: 70%;
      width: 100%;

      &.closed {
        height: 40px;

        .table-toggle-wrapper {
          top: auto;
          bottom: 0;

          svg.toggle-icon {
            transform: rotate(90deg);
          }
        }
      }

      .table-wrapper {
        height: 500px;
        overflow-y: auto;
      }

      .table-toggle-wrapper {
        top: -40px;
        right: 0;
        width: 100%;
        height: 40px;

        .table-toggle {
          width: 100%;
          border-radius: 0;
          box-shadow: none;
          height: 40px;

          svg {
            top: 5px;
            left: 50%;
            transform: rotate(270deg);
          }
        }
      }
    }
  }

  .trip-table-section {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    height: 100%;
    background-color: white;

    &.closed {
      table {
        display: none;
      }

      .table-toggle svg {
        transform: rotate(180deg);
        top: 0;
      }
    }

    table {
      border-spacing: 0;

      .icon.scope {
        margin-top: 5px;
      }


      th {
        background-color: $color-e8f3fc;
        font-size: 12px;
        color: $navy;
        padding: 12px;
        font-weight: normal;
      }

      td {
        font-size: 11px;
        color: $color-408aff;
        padding: 12px;
        font-weight: 600;
        border-bottom: solid 1px #ebebeb;

        &.insignificant {
          font-weight: normal;
        }
      }

      tr:hover {
        td {
          background-color: $fb-lightgray;
        }
      }
    }

    .table-toggle-wrapper {
      position: absolute;
      top: 10px;
      right: -34px;
      overflow: hidden;
      width: 35px;
      height: 50px;
    }

    .table-toggle {
      position: absolute;
      top: 0;
      left: 0;

      cursor: pointer;
      width: 27px;
      height: 26px;
      border-radius: 0 5px 5px 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: $white;

      svg {
        position: absolute;
        top: -5px;
        left: 7px;
      }
    }
  }

  .hovering-message {
    $width: 357px;

    position: absolute;
    top: 55px;
    left: calc(50% - #{$width / 2});
    z-index: 2;

    width: $width;
    height: 75px;
    border-radius: 6px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.19);
    background-color: $white;
    font-size: 15px;
    color: $color-9f9f9f;

    .hovering-message-content {
      margin-top: 27px;
      text-align: center;
    }

    .close-hovering-message {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 11px;
    }
  }
}

@media screen and (max-width: 700px) {
  div.trip-map-page {
    overflow: hidden;
  }
}
