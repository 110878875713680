@import './colors.scss';

.sro-table {
  span {
    font-size: 14px;
    color: $green-gray;
  }

  a span {
    color: $color-408aff;
  }

  th {
    font-size: 14px;
    background-color: $super-lightgray;
    height: 45px;
  }

  th,
  th span {
    color: $navy;
  }

  tr:hover td {
    background-color: $fb-lightgray;
    cursor: auto;
  }
}
