@import '../../colors.scss';

.notification-top-wrapper {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1400;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;

  .notification-middle-wrapper {
    transform: translate(0px, 0px);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    min-width: 288px;
    max-width: 568px;
    border-radius: 4px;
    color: $white;
    padding: 6px 24px;
    align-items: center;
    background-color: rgb(49, 49, 49);
    font-size: 0.875rem;
    font-weight: 400;
    font-family: 'Ubuntu', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.46429em;
    margin: 0;

    .notification-bottom-wrapper {
      padding: 8px 0;
      font-size: 0.875rem;
      font-weight: 400;
      font-family: 'Ubuntu', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      line-height: 1.46429em;
      text-align: center;
    }
  }
}
