@import '../colors.scss';

.activity-calendar-page {
  height: 100%;

  .date-picker-top {
    margin-right: 20px;
  }

  .sro-dropdown {
    background: transparent;
  }

  .printMode {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .agent-buttons,
    .agent-date-toggle {
      visibility: hidden;
    }
  }

  .agent-buttons {
    .right-item {
      padding: 21px 0;
    }

    .show-details {
      padding: 0;
      margin-left: 15px;
      background: $background-color;
    }
  }

  th {
    background-color: $color-eee;
    width: 964px;
    height: 45px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $navy;
  }

  .agent-date-col > div > * {
    float: left;
  }

  .date-text {
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
  }

  td.agent {
    background-color: white;
  }

  .agent-date-toggle {
    padding: 11px;
    height: 12px;
  }

  .agent-toggle {
    padding: 41px 20px;
  }

  tr.agent td {
    border: 1px solid $color-e9eff4;
  }

  td.agent-date-col,
  td.travel-col {
    width: 81px;
    height: 45px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $green-gray;
    border-bottom: solid 1px #ebebeb;
  }

  td.agent-date-col .icon.from,
  td.agent-date-col .icon.to {
    width: 10px;
    margin-right: 0;
  }

  td.agent-date-col {
    font-size: 18px;
    color: $color-969696;
    background-color: white;
  }

  td.agent-date-col .icon {
    height: 12.5px;
    margin-right: 12px;
  }

  td.agent-date-col .icon.location {
    margin-top: 5px;
  }

  td.agent-date-col .icon.user.unplanned {
    margin-top: 5px;
  }

  td.agent-date-col .icon.user.planned {
    margin-top: 5px;
  }

  td.travel-col {
    background-color: $white;
  }

  tr:hover td.travel-col {
    background-color: $fb-lightgray;
  }

  td.travel-col.rating-name {
    text-align: center;
    font-weight: bold;
    color: $orange;
  }

  .count-by-planned {
    width: 49px;
    font-size: 35px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.8;
    letter-spacing: normal;
    margin: auto;
  }

  .count-by-planned.planned {
    color: $color-7ed321;
  }

  .count-by-planned.unplanned,
  .count-by-planned.avg {
    color: $color-4a4a4a;
  }

  .by-planned-label {
    padding-top: 10px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-354052;
    margin: auto;
  }

  .by-planned {
    text-align: center;
  }

  div.left-item {
    float: left;
  }

  .right-item {
    float: right;
    margin-left: 5px;
  }

  .top-elements {
    height: 50px;
  }

  .activity-calendar-table {
    width: 976px;
    margin-top: 16px;
    border-spacing: 0;
  }

  .agent-profile-box {
    text-align: center;
  }

  .agent-profile-box * {
    margin: auto;
  }

  .agent-profile-box .profile-name {
    padding-top: 8px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.31px;
    color: $darkgray;
  }

  .agent-line-stat-box {
    width: 150px;
    padding: 21px 0;
  }

  a {
    color: $color-408aff;
  }

  .icon {
    margin-right: 3px;
  }

  .loading-gif {
    margin-top: 300px;
    margin: auto;
    display: block;
  }

  .no-items-to-show {
    text-align: center;
    margin-top: 35px;
  }

  .agent-spacing {
    height: 12px;
    background: transparent;
  }

  .visit-type-icon {
    position: absolute;
    top: -15px;
    left: 8px;
  }
}
