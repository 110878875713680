.mapboxgl-popup-tip {
  opacity: 0.9;
}

.mapboxgl-popup-anchor-top,
.mapboxgl-popup-anchor-bottom {
  .mapboxgl-popup-tip {
    border-top-color: rgba(57, 57, 57, 0.88) !important;
    border-bottom-color: rgba(57, 57, 57, 0.88) !important;
  }
}

.mapboxgl-popup-anchor-right,
.mapboxgl-popup-anchor-left {
  .mapboxgl-popup-tip {
    border-right-color: rgba(57, 57, 57, 0.88) !important;
    border-left-color: rgba(57, 57, 57, 0.88) !important;
  }
}

.mapboxgl-popup-content {
  opacity: 0.9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(57, 57, 57, 0.88);
  border-radius: 3px;
  padding: 5px;
}

.nearby-tooltip {
  th {
    font-weight: normal;
  }
  td {
    font-weight: bold;
    text-align: right;
  }
}
