@import '../../colors';

.sro-dropdown {
  width: 245px;
  height: 47px;
  position: relative;
  display: inline-block;
  background-color: white;
  text-align: left;
  cursor: pointer;

  .all-content {
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 10;
  }

  .selected-item {
    border: 1px solid $button-border;
    border-radius: 4px;

    &.no-selected {
      color: $placeholder-color;
    }

    &:hover {
      border-color: $button-border-hover;
    }
  }

  .options {
    max-height: 310px;
    border: 1px solid $button-border;
    border-top: none;
    overflow-y: scroll;
  }

  &.open {
    .selected-item {
      border-radius: 4px 4px 0 0;
      border-bottom: none;
    }

    .all-content {
      z-index: 11;
    }

    .option {
      width: 208px;
    }
  }

  .option,
  .selected-item {
    width: 223px;
    height: 47px;
    font-size: 14px;
    letter-spacing: normal;
    color: $darkgray;
    margin-top: -1px;
    padding-left: 20px;
    line-height: 3.5;
  }

  .option {
    border-top: solid 1px $color-b8c1c8;
    border-top-color: $color-e8e8e8;
    background-color: $color-f6f8f9;
  }

  .option:hover {
    background-color: white;
  }

  .last {
    border-radius: 0 0 4px 4px;
  }

  .toggle-wrapper {
    position: absolute;
    right: 1px;
    height: 47px;
    width: 50px;
    border-left: solid 1px $color-b8c1c8;
    border-radius: 0 4px 4px 0;
    background-color: $background-color;

    .toggle-button {
      height: 10px;
      margin: 14px 5px 0 20px;
    }
  }
}

.sro-geopicker-wrapper {
  position: relative;

  display: inline-block;
  width: 227px;
  height: 45px;

  .input-box {
    text-align: left;
    position: relative;
    z-index: 0;
  }

  .autocomplete-dropdown-container-wrapper {
    position: relative;

    .autocomplete-dropdown-container {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.sro-geopicker-wrapper.has-title {
  .sro-input {
    top: 26px;
  }
}

.sro-input, .sro-geopicker-wrapper {
  &.has-error {
    input {
      border-color: red;
    }

    .sro-input.sro-geoinput .geo-input-button {
      border-right-color: red;
      border-top-color: red;
      border-bottom-color: red;
    }
  }
}

.sro-input {
  display: inline-block;
  width: 223px;
  position: relative;
  z-index: 0;

  input {
    width: 100%;
    padding-left: 20px;
    height: 45px;
    border-radius: 4px;
    border: solid 1px $color-b8c1c8;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $placeholder-color;
    }
  }

  &.sro-geoinput {
    width: 202px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .geo-input-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 47px;
      height: 47px;
      background-color: $color-fbfcfd;
      border-radius: 0 4px 4px 0;
      border: solid 1px $color-b8c1c8;

      .icon.geoInput {
        position: absolute;
        top: 15px;
        left: 17px;
      }
    }

    input {
      border-radius: 4px;
      width: calc(100% - 62px);
    }

    .suggestion-item,
    .suggestion-item--active {
      height: 45px;
      line-height: 45px;
      padding: 0 5px;

      border: solid 1px $color-b8c1c8;
      font-size: 13px;
      color: $darkgray;
      margin-top: -1px;
      background-color: $color-f4f8f9 !important;
      display: block;

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }

      &.suggestion-item--active {
        background-color: white !important;
      }
    }
  }
}

button.sro-button {
  width: auto;
  padding: 6px 30px;
  border-radius: 4px;
  border: solid 1px #d0d0d0;
  background-color: $white;
  font-size: 13px;
  line-height: 2;
  letter-spacing: normal;
  text-transform: none;

  svg {
    display: none;
  }

  &:hover {
    background-color: $extra-lightblue;
    border-color: $extra-lightblue;
  }

  &.sro-button-white,
  &.sro-button-white:hover {
    background-color: white;
    color: $darkgray;
    border: solid 1px #d0d0d0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.21);
  }

  &.sro-button-secondary,
  &.sro-button-secondary:hover {
    background-color: white;
    border-color: $extra-lightblue;
    color: $middle-blue;

    &:hover {
      color: $extra-lightblue;
      border-color: $extra-lightblue;
    }

    span {
      color: $middle-blue;

      &:hover {
        color: $extra-lightblue;
      }
    }
  }
}

.sro-form-component, .sro-form {
  .upper-title {
    opacity: 0.7;
    font-size: 12px;
    line-height: 2.17;
    color: $darkgray;
  }
}

.geo-form-picker {
  position: relative;
  margin-bottom: 20px;

  label.field-title {
    display:block;
    clear: both;
    opacity: 0.7;
    font-size: 12px;
    color: $darkgray;
    margin-bottom: 10px;
    padding-left: 0px;
  }

  .hidden-text-field {
    display: none;
  }

  .sro-input.sro-geoinput .geo-input-button {
    right: -1px;
  }

  .sro-geoinput {
    width: 256px;
    border-radius: 4px;
  }
}

.sro-form-input {
  input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark-color(black, white);
    text-transform: none;
    text-indent: 0px;
    text-align: start;
    -webkit-appearance: textfield;
    margin: 0em;
    font-weight: 400;
    font-size: 13px;
    padding: 1px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
  }

  textarea {
      padding: 5px;
      border-radius: 4px;
      border: 1px $color-b8c1c8 solid;
      height: 47px;
      font-weight: 400;
      font-size: 13px;
      width: 100%;
  }
}


.sro-tab {
  a[role='tab'] {
    border-color: $color-2888d0;
    border-width: 13px;
  }

  span[class*='TabIndicatorroot-'] {
    display: none;
    padding-left: 0;
  }

  span[class*='MuiTablabelContainer-'] {
    padding: 0;
  }

  hr {
    display: none;
  }

  a[class*='MuiButtonBaseroot-'] {
    padding: 0 15px;
    display: block;
    min-height: 0;
    min-width: 120px;
    text-align: center;

    &[aria-selected='true'] {
      > span[class*='MuiTabwrapper-'] {
        border: $color-5788cb 1px solid;
        border-width: 0 0 4px 0;
      }
    }

    > span[class*='MuiTabwrapper-'] {
      display: inline-block;
      padding: 15px 0 7px 0;
      width: auto;
      margin: 3px 0 0 10px;

      > span {
        display: inline-block;
        height: 20px;

        > span[class*=MuiTablabel] {
          height: 50px;
          display: block;
          display: inline;
          font-size: 15px;
          text-overflow: clip;
          text-align: center;
          text-transform: none;
        }
      }
    }
  }
}

@media (min-width: 960px) {
  div[class*=MuiTablabelContainer] {
    div[class*=MuiTablabel] {
      font-size: 20px;
    }
  }
}

.back-from-edit-wrapper {
  height: 60px;
  width: 100%;

  .link-style-button {
    &,
    &:hover {
      float: right;
      color: $color-408aff;
      background-color: transparent;
      border-width: 0;
      box-shadow: none;
      margin-top: 13px;
      text-transform: none;
    }
  }
}

.sro-side-table {
  font-size: 13px;
  line-height: 2;
  color: $darkgray;
  margin-top: 0px;
  width: 300px;

  th, td {
      overflow: hidden;
      text-align: left;
      height: 50px;
      font-weight: normal;
      width: 150px;
  }

  td {
    font-weight: bold;
  }
}

.list-page, .edit-page, .create-page, .show-page {
  > div {
    box-shadow: none;
  }
}

div[class*=MuiToolbar] {
  padding-left: 16px;
  background-color: $background-color;
}
