.question-mark-with-tooltip {
  .question-mark {
    margin: 0 auto;
    font-weight: bold;
    border: 2px black solid;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
    line-height: 1.9;
  }
}
