@import '../../../colors.scss';

.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar,
  .MuiPickersClockPointer-pointer,
  .MuiPickersClock-pin,
  .MuiPickersClockPointer-noPoint {
    background-color: $middle-blue;
  }

  .MuiPickersClockPointer-thumb {
    border-color: $middle-blue;
  }
}

.sro-time-input.MuiFormControl-root.MuiFormControl-fullWidth {
  min-width: 70px;
}
