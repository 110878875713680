@import '../../../colors.scss';

.sro-secret-input {
    .upper-title {
        margin-top: 15px;
    }

    .reveal-button {
        cursor: pointer;
        font-size: 13px;
        line-height: 2;
        color: $color-0c59ff;
        margin-bottom: 10px;
    }

    .secret-modal-wrapper {
        position: absolute;
        top: 20%;
        left: 50%;
        width: 1px;
        height: 1px;
        z-index: 2;

        .secret-modal {
            $width: 600px;
            width: $width;

            position: absolute;
            left: -$width / 2;
            text-align: center;
            background-color: white;
            padding: 20px;
            border: 0;
            border-radius: 10px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            font-weight: normal;

            .close-button {
                position: absolute;
                top: 0;
                right: 10px;
                color: $middle-gray;
                cursor: pointer;
            }
        }
    }
}
