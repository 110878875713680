@import '../../colors.scss';

.analytics-shadow {
  width: 1230px;
  margin: 90px auto 0 auto;
  $filler-color: $super-lightgray;

  .box {
    display: inline-block;
    margin-right: 20px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    &.pie-pair {
      width: 848px;
    }

    &.pie-single {
      display: inline-block;
      width: 416px;
    }
  }

  .line {
    margin-top: 20px;
  }

  .col-chart-1 {
    width: 1208px;
    height: 390px;
    position: relative;

    .horizontal {
      position: absolute;
      top: 40px;
      left: 55px;

      width: 113px;
      height: 9px;
      background-color: $filler-color;
    }

    .vertical {
      position: absolute;
      bottom: 35px;

      width: 12px;
      background-color: $filler-color;
    }
  }

  .col-chart-2 {
    width: 594px;
    height: 342px;
    position: relative;

    .horizontal {
      position: absolute;
      top: 40px;
      left: 55px;

      width: 113px;
      height: 9px;
      background-color: $filler-color;
    }

    .vertical {
      position: absolute;
      bottom: 35px;

      width: 12px;
      background-color: $filler-color;
    }
  }
}
