@import '../../colors.scss';

.step-2-shadow {
    $filler-color: $super-lightgray;
    padding: 24px;

    .step-title {
        width: 164px;
        height: 9px;
        background-color: $filler-color;
        margin: 28px 0 40px 0;
    }

    .table-header {
        width: 100%;
        height: 18px;
        background-color: $filler-color;
        margin-bottom: 20px;
    }

    .table-line {
        margin-bottom: 18px;

        .shadow-input {
            display: inline-block;
            background-color: $filler-color;
            margin-right: 20px;
    
            &.small-input {
                width: 61px;
                height: 9px;
                margin-right: 47.5px;
            }
        
            &.medium-input {
                width: 72px;
                height: 24px;
            }
        
            &.large-input {
                width: 113px;
                height: 24px;
                margin-right: 0;
            }    
        }
    }
}