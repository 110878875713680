@import '../../colors.scss';

.date-picker-top {
  position: relative;

  .date-picker-ro {
    width: 199.5px;
    height: 47px;
    border-radius: 4px;
    border: 1px solid $color-b8c1c8;
    cursor: pointer;

    &:hover {
      border-color: $color-a4adb4;
    }
  }

  .date-display {
    float: left;
    width: 152px;
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: normal;
    color: $darkgray;
    text-align: center;
    padding-top: 12px;
  }

  .calendar-button {
    float: left;
    width: 13px;
    border-left: 1px $color-b8c1c8 solid;
    padding: 18px 13px 18px 17px;
  }

  &.full-width {
    width: 100%;

    .date-picker-ro {
      width: 100%;
    }

    .date-display {
      width: calc(100% - 50px);
    }
  }
}

.date-picker-wrapper {
  // position: relative;

  .react-datepicker,
  .react-datepicker__day {
    font-family: 'Ubuntu', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .date-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: white;
    width: 590px;
    padding: 15px;
    border-radius: 4px;
  }

  .date-picker.single {
    width: 240px;
  }

  .calendar-wrapper {
    float: left;
    margin-right: 20px;
  }

  .react-datepicker__day-name {
    font-size: 14px;
    line-height: 2;
    letter-spacing: normal;
    color: $color-989898;
  }

  .date-picker {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
  }

  .react-datepicker {
    border-width: 0;
  }

  .react-datepicker__header {
    border-width: 0;
  }

  .react-datepicker__day {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.92px;
    color: $color-999999;
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    border-radius: 4px;
    background-color: rgba(77, 161, 255, 0.1);
  }

  .react-datepicker__header {
    background-color: white;
  }

  .react-datepicker__current-month {
    font-size: 14px;
    line-height: 2;
    letter-spacing: normal;
    color: $color-989898;
  }

  .react-datepicker__navigation {
    border-color: $middle-blue;
    border-width: 2px 2px 0 0;
    background-color: white;
    transform: rotate(225deg);
    outline-width: 0;
  }

  .react-datepicker__navigation--next {
    transform: rotate(45deg);
  }

  .react-datepicker__navigation:hover {
    border-color: $extra-lightblue;
  }
}

.sro-form {
  .date-picker-ro,
  .date-picker-ro svg {
    border-color: $color-b8c1c8;
  }

  .date-picker-ro g {
    fill: #b2bac5;
  }
}
