@import '../../colors.scss';

.pie-wrap {
  position: relative;

  .percentage-wrapper {
    position: absolute;
    top: 50%;
    left: 3px;
    width: 136px;
    text-align: center;
    line-height: 30px;

    .percentage {
      margin-top: -18px;
      font-size: 36px;
      color: $color-5e5e5e;
    }
  }
}

.nested-pie {
  position: relative;

  .pie-tooltip,
  .pie-tooltip .triangle {
    border: solid 0.5px $color-2888d0;
    background-color: white;
    opacity: 0.9;
    z-index: 1;
  }

  &:hover .pie-tooltip {
    display: block;
  }

  .pie-tooltip {
    position: absolute;
    top: 0;
    left: 80px;
    width: 120px;
    height: 77px;
    display: none;

    .triangle {
      display: block;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 35px;
      left: -5px;

      border-width: 0 0 0.5px 0.5px;
      transform: rotate(45deg);
    }

    ul {
      font-size: 12px;
      line-height: 25px;
      text-align: left;
      padding-inline-start: 22px;
    }
  }
}
