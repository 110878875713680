@import '../colors.scss';

.login-page {
  .login-background {
    position: absolute;
    z-index: 1;

    overflow: hidden;

    top: 0;
    right: 0;
    height: 100vh;

    width: calc(100% - 630px);
    border-left: solid 1px #979797;
    background-image: linear-gradient(135deg, #47caaa 5%, #2a6ab3 99%);
    text-align: center;

    .login-title {
      padding-top: 120px;
      width: 388px;
      height: 151px;
      font-size: 50px;
      letter-spacing: -1.71px;
      text-align: center;
      color: $white;
      display: block;
      margin: auto;
    }

    .login-subtitle {
      width: 309px;
      height: 36px;
      font-size: 25px;
      letter-spacing: -0.85px;
      color: $white;
      display: block;
      margin: auto;
    }

    .add-spot {
      position: absolute;
      top: 580px;
      right: 90px;
    }

    .add-spot-action {
      position: absolute;
      top: 355px;
      right: 430px;
    }

    .path {
      position: absolute;
      top: 500px;
      right: 300px;
    }

    .pie {
      position: absolute;
      top: 600px;
      left: -50px;
      transform: rotate(30deg);
      width: 200px;
    }

    .signal-bars {
      position: absolute;
      bottom: -70px;
      left: 250px;
      width: 300px;
      transform: rotate(-20deg);
    }

    .success {
      position: absolute;
      top: 500px;
      right: -50px;
      width: 150px;
      transform: rotate(-25deg);
    }
  }

  .login-flow-form-wrapper {
    position: relative;
    z-index: 2;
    padding: 150px 0 0 100px;
    vertical-align: middle;

    .login-flow-form {
      text-align: center;
      width: 380px;
    }
  }

  .sro-input {
    display: block;
    margin-bottom: 20px;

    input {
      font-size: 15px;
      width: 380px;
      height: 74px;
      border-radius: 6px;
      border: solid 1px #d5d5d5;
    }
  }

  .sro-button.reset-password-button {
    height: 19px;
    opacity: 0.5;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: $middle-blue;
    text-align: center;
    display: inline-block;
    margin-bottom: 67px;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .sro-button.reset-password-button:hover {
    opacity: 1;
  }

  .sro-button {
    width: 271px;
    height: 56px;
    border-radius: 6px;
    background-color: $middle-blue;
  }
}

.amodat-logo {
  margin-bottom: 68px;
  width: 243px;
}

div[class*='Layoutcontent-'].resource-verify {
  background-color: white;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.password-reset-page {
  .form-title {
    font-size: 25px;
    letter-spacing: -0.51px;
    color: $darkgray;

    margin-bottom: 50px;
  }

  .amodat-logo {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .login-flow-form {
    margin: auto;
    width: 385px;
    text-align: center;

    .sro-input,
    .sro-button {
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }

    .sro-input {
      width: 380px;
      margin-left: -8px;
    }

    .upper-title {
      margin-bottom: 5px;
      margin-left: -7px;
      text-align: left;

      font-size: 16px;
      line-height: 1.69;
      letter-spacing: normal;
      color: $color-354052;
    }
  }

  .success-message-box {
    width: 300px;
    margin: auto;
    border-radius: 8px;
    padding: 10px;
    margin-top: 200px;

    a {
      display: block;
      margin-top: 20px;
      color: $color-7fbcfa;
    }
  }

  .sro-button {
    &,
    &:hover {
      background-color: $middle-blue;
    }
  }
}
