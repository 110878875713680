@import '../colors.scss';

.shadow-wrapper {
  width: 976px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-top: 20px;

  .shadow-table {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    width: 100%;

    div {
      background-color: $extra-lightgray;
      margin: 12px;
      height: 17px;
    }

    .row_0 {
      opacity: 1;
    }

    .row_1 {
      opacity: 0.5;
    }

    .row_2 {
      opacity: 0.33;
    }

    .row_3 {
      opacity: 0.25;
    }

    .row_4 {
      opacity: 0.2;
    }
  }
}
