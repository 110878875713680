@import '../colors.scss';

.dashboard {
  margin: 0 auto;

  .page-title {
    margin-left: 10px;
  }

  .filter {
    .date-picker-top,
    .sro-form-component {
      float: left;
      margin: 0 10px 20px 10px;
    }

    .sro-dropdown {
      &,
      .selected-item,
      .toggle-wrapper {
        background-color: transparent;
      }
    }
  }

  .dashboard-container {
    .dashboard-row {
      clear: both;
      display: flex;
      justify-content: space-between;
    }

    .cells-2 > div {
      height: 350px;
    }

    .cells-3 > div {
      height: 300px;
    }

    .dashboard-cell {
      &.col-3 {
        padding: 15px 40px;
      }

      overflow: hidden;
      display: inline-block;
      text-align: center;
      background-color: white;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      padding: 15px;
      margin: 10px;
    }
  }

  .chart-title {
    font-size: 22px;
    letter-spacing: -0.14px;
    color: #485465;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  .sro-gauge {
    margin: 41px 95px;
  }

  .pie-tooltip {
    ul {
      display: block;
      padding: 15px 10px;
      list-style-type: none;
      text-align: left;
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: -0.07px;
      color: #ffffff;
      padding-left: 0;
    }

    .tooltip-highlight {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .group-title {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    padding-left: 15px;
  }

  .tooltip-item {
    text-align: left;
    color: $white;
    font-size: 11px;
    letter-spacing: -0.07px;
    margin-top: -10px;

    .tooltip-item-text {
      margin-left: 5px;
      display: inline-block;
    }

    .tooltip-item-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 15px;
    }
  }

  .actual-visit-tooltip {
    color: white;
    border-radius: 4px;
    text-align: left;
    font-size: 12px;
    padding: 10px;

    .background {
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.7;
    }

    .list-title {
      display: inline-block;
      z-index: 11;
      text-align: left;
      margin-bottom: 10px;
    }

    .item-list {
      text-align: left;
      margin: 5px;
      position: relative;
      z-index: 10;

      .item-line {
        .item-circle {
          margin-right: 8px;
          width: 10px;
          height: 10px;
          border-radius: 10px;

          display: inline-block;
        }

        .item-title {
          margin-top: 8px;
          display: inline-block;
        }
      }
    }
  }
}
