@import '../colors.scss';

.agent-edit-wrapper {
  position: relative;
  margin-left: 20px;

  .agent-objective-table {
    font-size: 13px;

    .MuiFormControl-root {
      width: 90px;
      direction: ltr;
    }

    th {
      width: 90px;
      height: 36px;
      background-color: $super-lightgray;
      font-size: 12px;
      text-align: center;
      color: $navy;
      padding: 0 5px;
      font-weight: 300;
    }

    td {
      color: $green-gray;
    }

    input {
      height: 25px;
    }
  }

  .icon.x {
    display: none;
  }

  .tabbed-form {
    div[class*='MuiCardContent'] {
      padding-top: 30px;
    }
  }

  .section-title {
    display: none;
  }

  .save-rating-table {
    position: absolute;
    bottom: 8px;
    z-index: 2;
    height: 48px;
    width: 100px;
  }

  div[class*='startLocationStringified'] {
    position: relative;
    z-index: 2;
  }

  div[class*='endLocationStringified'] {
    position: relative;
    z-index: 1;
  }

  .percent,
  .absolute-view {
    display: inline;
  }

  div[class*='MuiToolbar'] {
    display: block;
  }

  .sro-button {
    margin-top: 10px;
  }

  span[class*='MuiTab'] {
    font-weight: normal;
    cursor: pointer;
  }

  .sro-form-wrapper {
    > tbody > tr > td {
      vertical-align: top;
      width: 256px;
      padding-right: 30px;
    }
  }
}

.agents-page {
  .agent-list {
    td,
    th {
      text-align: left;
    }
  }
}
