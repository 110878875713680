.sro-line-chart {
    position: relative;

    .line-tooltip {
        position: relative;
        color: white;
        border-radius: 4px;
        padding: 0 10px;

        line-height: 3;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        .line-tooltip-background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            border-radius: 4px;
            background-color: black;
            opacity: 0.6;
            width: 100%;
            height: 100%;
        }
    }

    .recharts-legend-wrapper {
        .recharts-default-legend {
            font-size: 13px;
        }
    }
}
