@import '../../colors.scss';

$margin: 20px;

.trip-map-popup-wrapper {
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;

    .trip-map-popup {
        overflow: hidden;
        margin: $margin;
        width: calc(100vw - #{$margin * 2});
        height: calc(100vh - #{$margin * 2});
        border-radius: 5px;
        border: solid 1px $color-f4f8f9;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
}