.clients-page {
  position: relative;

  .list-view {
    > div {
      overflow: visible;
    }

    .id-col {
      position: relative;
      width: 70px;
      padding-left: 30px;

      .error-icon {
        position: absolute;
        top: -15px;
        left: -7px;
      }

      .info-icon {
        position: absolute;
        top: 15px;
        left: -5px;
      }
    }

    .sro-dropdown {
      width: 120px;
    }

    .sro-dropdown.open .option {
      width: auto;
    }

    .sro-dropdown .option,
    .sro-dropdown .selected-item {
      width: 110px;
    }

    .last-visit-date-col {
      .visit-type-icon {
        padding-left: 6px;
      }
    }
  }
}
