@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

@import './plugin-stylesheets/sro-mapbox.scss';
@import './components/colors.scss';

$font-family: 'Ubuntu', 'Roboto', 'Helvetica', 'Arial', sans-serif;

body {
  font-family: $font-family;
  margin: 0;
}

.rtl {
  direction: rtl;
}

input,
textarea {
  font-family: $font-family;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: $super-darkgray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $cyan;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

button.sro-button,
a.sro-button {
  color: white;
  border-radius: 5px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: $middle-blue;

  .MuiCircularProgress-root {
    display: none;
  }
}

button.sro-button:hover,
a.sro-button:hover {
  background-color: $extra-lightblue;
}

.sro-form {
  input,
  div[class*='MuiSelect'] {
    border-radius: 4px;
    border: 1px $button-border solid;
    height: 47px;
    padding: 0 0 0 15px;
  }

  .MuiInputBase-input {
    font-size: 14px;
  }

  input:hover {
    border-color: $button-border-hover;
  }

  label[class*='MuiFormLabel'] {
    &:hover {
      background: transparent;
    }
  }

  div[class*='MuiInput']:before {
    border-bottom-width: 0;
  }

  div[class*='MuiSelect'] {
    padding: 6px 25px 8px 12px;
    border-radius: 4px;
    border: 1px solid $color-b8c1c8;
    height: 33px;
  }

  div[class*='MuiSelect']:hover {
    border-color: $button-border-hover;
  }

  div[class*='MuiInput'] {
    &:before,
    &:after {
      content: '' !important;
      transition: none !important;
      transform: none !important;
      border-bottom-width: 0 !important;
      border-bottom: none !important;
    }
  }

  div[class*='MuiInput'],
  div[class*='MuiInput']:hover {
    line-height: 2.3;
    margin-top: 0;
    background: transparent;
  }

  div[class*='MuiInput']:after,
  div[class*='MuiInput']:before,
  input:before,
  input:after {
    transition: none;
    transform: none;
    border-bottom-width: 0;
    content: '';
    position: relative;
  }

  label {
    font-size: 16px;
    padding-left: 5px;
    transition: none;
  }

  .MuiFormHelperText-marginDense {
    margin-top: 0px;
    margin-left: 0px;
    font-size: 10px;
  }

  .MuiFormControl-marginDense {
    margin-top: 22px;
  }

  .MuiFormControl-root.MuiFormControl-fullWidth {
    margin-top: 24px;
  }

  .MuiFormLabel-root.MuiInputLabel-filled.MuiInputLabel-shrink,
  .MuiFormLabel-root.MuiFormLabel-root {
    transform: translate(-4px, -21px) scale(0.8);
  }

  .MuiFormLabel-root.Mui-focused {
    color: $darkgray;
  }
}

.page {
  margin: 0 auto;
}

.main-page {
  margin-left: 20px;
}

.list-view {
  .page-title {
    position: absolute;
    z-index: 2;
  }

  .sro-filter {
    margin-top: 0;
  }

  .sro-form {
    margin-left: 200px;

    input {
      width: 130px;
    }
  }
}

.edit-title {
  font-size: 22px;
  font-weight: normal;
  color: $darkgray;
}

.page-title {
  width: 210px;
  margin: 10px 15px 0 0;
  font-size: 22px;
  font-weight: normal;
  color: $darkgray;
}

.float-left {
  float: left;
}

.float-right {
  float: left;
}

.bold {
  font-weight: bold;
}
