@import '../../colors.scss';

.percentage-input {
  background-color: white;

  > * {
    display: inline-block;
  }

  .percent {
    background-color: $super-lightgray;
    padding: 3px;

    > * {
      display: inline;
    }

    input {
      text-align: right;
      border-width: 0;
      outline: none;
      width: 25px;
      height: 21px;
      font-size: 11px;
      color: $green-gray;
      background-color: transparent;
    }
  }

  .absolute-view {
    margin-left: 5px;
    color: $green-gray;
    font-size: 11px;
    width: 36.9px;
    height: 24px;
  }
}
