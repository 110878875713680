.sro-gauge {
  position: relative;

  .sro-gauge-frame {
    position: relative;
    display: inline-block;
    background-image: linear-gradient(#c6ea66, #5380d9);
    height: 200px;
    width: 200px;

    .pie {
      transform: scale(1, -1) rotate(90deg);
    }

    .percent-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 5.5;
      text-align: center;

      font-size: 36px;
      letter-spacing: 0.92px;
      color: #5e5e5e;
    }

    .value-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 13.5;
      text-align: center;

      font-size: 20px;
      letter-spacing: 0.92px;
      color: #5e5e5e;
    }
  }

  &:hover {
    .sro-gauge-tooltip {
      display: block;
    }
  }

  .sro-gauge-tooltip {
    display: none;
    $width: 150px;

    position: absolute;
    top: -10px;
    left: 215px - $width;
    z-index: 2;
    width: $width;
    height: 40px;
    opacity: 0.8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    .tooltip-content {
      padding: 10px;
      position: relative;
      background-color: rgba(57, 57, 57, 1);
      color: white;
      z-index: 2;
    }

    .tooltip-triangle {
      $triangleWidth: 40px;
      position: absolute;
      bottom: -20px;
      left: ($width - $triangleWidth) / 2;
      z-index: 1;
      width: $triangleWidth;
      height: 40px;
      transform: rotate(45deg);
      background-color: rgba(57, 57, 57, 1);
    }
  }
}
