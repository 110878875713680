.sro-map {
  &.sro-google-maps-map-view {
    .map-buttons {
      top: auto;
      bottom: 200px;

      .map-button {
        height: 40px;
        width: 40px;
        line-height: 2.5;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  .map-buttons {
    position: absolute;
    top: 0;
    right: 1px;
    z-index: 2;

    .map-button {
      background-color: white;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      border-radius: 4px;
      margin: 8px;
      line-height: 2;

      .icon.scope {
        margin-top: 7px;
        width: 25px;
      }

      &.layers {
        padding: 6px 0 0 2px;
        height: 34px;
        width: 38px;
      }
    }
  }

  .gm-style .gm-style-iw-t::after {
    background: linear-gradient(
      45deg,
      rgba(57, 57, 57, 0.7) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
    content: '';
    height: 15px;
    left: -8px;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
  }

  .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  .gm-ui-hover-effect {
    img {
      display: none !important;
    }

    &:after {
      content: 'x';
      color: white;
      font-weight: bold;
      font-size: 17px;
    }
  }

  .gm-style-iw {
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(57, 57, 57, 0.88);
    border-radius: 3px;
    padding: 5px;
    margin-left: -12px;
  }

  div.tooltip-title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  table.client-tooltip {
    font-weight: bold;

    th {
      text-align: left;
      font-weight: normal;
    }

    td {
      text-align: right;
      color: white;
    }
  }
}
