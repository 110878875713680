@import '../../colors.scss';

.dashboard-shadow {
  width: 1300px;
  margin: 70px auto 0 auto;
  $filler-color: $super-lightgray;

  .box {
    display: inline-block;
    margin-right: 16px;
    width: 416px;
    height: 342px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    &.pie-single {
      display: inline-block;
      width: 416px;
    }

    &.col-chart {
      width: 632px;
    }
  }

  .line {
    margin-top: 10px;
  }

  .pie {
    display: inline-block;
    position: relative;
    width: 400px;
    height: 342px;
    margin-right: 22px;

    .circle {
      position: absolute;
      top: 72px;
      left: 147px;
    }

    .legend {
      position: absolute;
      top: 25px;
      left: 20px;

      .legend-line {
        width: 74px;
        height: 9px;
        margin-top: 16px;
        background-color: $filler-color;
      }
    }
  }

  .box.gauge {
    position: relative;

    .gauge-title {
      position: absolute;
      top: 19px;
      left: 26px;
      width: 107px;
      height: 9px;
      background-color: $filler-color;
    }

    .circle {
      position: absolute;
      top: 78px;
      left: 100px;
    }
  }

  .circle {
    $circle-outer: 205px;
    $circle-inner: 190px;
    position: relative;

    .outer {
      background-color: $filler-color;
      width: $circle-outer;
      height: $circle-outer;
      border-radius: $circle-outer;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .inner {
      background-color: $white;
      width: $circle-inner;
      height: $circle-inner;
      border-radius: $circle-inner;
      position: absolute;

      top: ($circle-outer - $circle-inner) / 2;
      left: ($circle-outer - $circle-inner) / 2;
      z-index: 2;
    }
  }

  .col-chart {
    position: relative;

    .horizontal {
      position: absolute;
      top: 40px;
      left: 55px;

      width: 113px;
      height: 9px;
      background-color: $filler-color;
    }

    .vertical {
      position: absolute;
      bottom: 35px;

      width: 12px;
      background-color: $filler-color;
    }
  }
}
