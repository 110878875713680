@import '../../colors.scss';

.profile-menu {
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 100%;
  line-height: 2.4;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .profile-icon {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    width: 20px;

    img {
      position: absolute;
      top: -15px;
      left: -3px;
      width: 22px;
    }
  }

  .username {
    padding-bottom: 5px;
    display: inline-block;
    color: white;
    margin-left: 20px;
  }

  .profile-line {
    width: 100%;
    height: 45px;
    overflow: hidden;
  }

  .menu-items {
    position: absolute;
    top: 30px;
    left: 15px;
    z-index: 20;
    background-color: white;
    border-radius: 4px;

    display: none;

    &.open {
      display: block;
    }

    .menu-item {
      width: 200px;
      height: 45px;
      padding-left: 10px;
      line-height: 2.7;
      border-radius: 4px;
      border: 1px #E0E0E0 solid;

      &:hover {
        background-color: $super-lightgray;
      }
    }
  }
}
