@import '../../colors.scss';

.activity-wrapper {
  position: relative;

  .activity-hover-modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;

    border-radius: 6px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.19);
    background-color: $white;
  }

  .activity-agent-name {
    margin: 25px;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $darkgray;
  }

  .bottom-elements {
    margin: 15px 20px 0 0;
    background-color: white;
  }

  .bottom-elements button {
    float: right;
    margin-right: 12px;
  }

  .activity-form-error {
    background-color: $color-fee;
  }

  button.activity-button-save {
    padding: 5px 45px;
    color: white;
    border-radius: 2px;
    border: solid 1px rgba(77, 161, 255, 0.7);
    background-color: $middle-blue;
    margin: 0 4px 24px 0;
  }

  button.activity-button-cancel {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $middle-blue;
  }

  .x-close-button {
    position: absolute;
    top: 16px;
    right: 10px;
    width: 11px;
    height: 9px;
  }

  .date-picker-top {
    width: 200px;
    display: block;
    margin-top: 32px;
  }

  div[class*='MuiCardContent'] {
    padding-right: 0;
  }

  .sro-form-input {
    display: block;
    clear: both;
  }

  .visit-remark {
    margin-top: 0;
    width: 100%;

    input {
      height: 100px;
    }
  }

  textarea {
    width: 428px;
    height: 80px;
    padding: 10px;

    &:focus {
      outline: none;
    }
  }

  .MuiAutocomplete-root {
    width: 246px;

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 7px 0 7px 20px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $color-b8c1c8;
    }

    .MuiOutlinedInput-notchedOutline:hover {
      border-color: $color-b8c1c8;
    }

    .MuiInputBase-input {
      color: black;
      font-size: 13px;
    }
  }

  ul[class*='AutocompleteInputsuggestionsList'] {
    width: 240px;
  }

  .sro-autocomplete input {
    border: 1px solid $color-b8c1c8;
    padding-left: 10px;
  }

  .simple-form {
    margin-top: -40px;
  }

  .sro-input {
    div[class*='MuiInput'] {
      &:before {
        content: none;
      }
    }

    input[class*='MuiInput'] {
      border-style: solid;
      border-width: 1px;
      border-color: $color-b8c1c8;

      &:after {
        content: none;
      }
    }

    label {
      width: 100px;
    }
  }

  table.create-activity-modal-table {
    margin: 20px;
    width: 500px;

    .simple-form > div {
      margin-left: 0;
      padding-left: 0;
    }

    th {
      padding-top: 20px;
      text-align: left;
      background-color: white;
      height: 20px;
      opacity: 0.7;
      font-size: 12px;
      color: $darkgray;
    }

    .date-picker-top {
      margin-top: 0;
    }

    .date-picker-ro {
      border-color: $color-b8c1c8;
    }

    .calendar-button {
      border-left-color: $color-b8c1c8;
    }
  }
}
