@import '../colors.scss';

.order-drilldown-modal-point {
  $modal_width: 650px;
  $modal_height: 500px;
  $content_spacing_top: 20px;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11;
  height: 1;
  width: 1;

  .order-drilldown-modal {
    position: absolute;
    top: -($modal_height / 2);
    left: -($modal_width / 2);
    z-index: 2;
    width: $modal_width;
    height: $modal_height;

    border: 1px white solid;
    box-shadow: 0px 0px 10px 10px #eee;
    border-radius: 5px;

    display: none;
    background-color: $modal_bg;

    &.shown {
      display: block;
    }
  }

  .order-drilldown-list {
    height: 150px;
    overflow-y: auto;

    table {
      border-collapse: collapse;
    }

    .colored {
      background-color: $order-drilldown-colored-line;
    }
  }

  .order-drilldown-modal-title {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
    color: $modal_title_color;
  }

  .order-drilldown-modal-content {
    padding-top: $content_spacing_top;
    width: $modal_width - 16px;
    height: $modal_height;
    text-align: right;
    margin-left: 8px;

    table {
      width: 100%;
    }
  }

  .order-drilldown-modal-x {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .no-info {
    color: $no_info_color;
    $font_size: 48px;
    font-size: $font_size;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: ($modal_height - $font_size) / 2 - $content_spacing_top;
  }

  .do-print-back-checkbox {
    background-color: $super-lightgray;

    &:hover {
      background-color: $extra-lightgray;
    }
  }
}

.field-col {
  font-size: 14px;
  height: 42px;

  .field-col-value {
    font-weight: bold;
  }
}

.daily-activity-page {
  height: 100%;

  .agent-spacing {
    height: 24px;
    overflow: hidden;
  }

  .order-count {
    &:hover {
      background-color: $hover_highlight_color;
      cursor: pointer;
    }
  }

  .daily-activity-content {
    width: 1076px;
  }

  th {
    min-width: 20px;
    background-color: $super-lightgray;
    font-size: 12px;
    color: $navy;
    padding: 0 5px;
    height: 36px;
    font-weight: 300;
  }

  .agent-summary-pair-line.agent-toggle {
    float: right;
    clear: none;
    padding-top: 50px;
    padding-right: 0px;
    width: 65px;
    height: 70px;
  }

  .agent-toggle {
    position: relative;
  }

  .artificial-shadow-source {
    position: relative;

    .artificial-shadow {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .left-shadow,
  .right-shadow {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;

    &.left-shadow {
      left: 0;
    }

    &.right-shadow {
      right: 0;
    }
  }

  .info-icons .left-shadow {
    left: -5px;
  }

  .top-shadow,
  .bottom-shadow {
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;

    &.top-shadow {
      top: 0;
    }

    &.bottom-shadow {
      bottom: 0;
    }
  }

  .detail-header {
    .right-shadow {
      right: -1px;
    }

    .left-shadow {
      left: -1px;
    }
  }

  .details .artificial-shadow {
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: 1px;
  }

  .agent-summary-column {
    display: inline;
    width: 120px;
    float: left;
  }

  .global-summary {
    display: block;
    clear: both;
    height: 216px;

    .global-summary-box {
      margin: 8px 0 8px 0;
      padding: 20px;
      width: 300px;
      display: inline;
      float: left;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

      &.central {
        margin: 8px 28px;
      }
    }

    .global-summary-title,
    .global-summary-value {
      float: left;
    }

    .global-summary-title {
      max-width: 130px;
      font-size: 22px;
      letter-spacing: -0.14px;
      color: $color-485465;
    }
  }

  div[role='toolbar'] {
    display: none;
  }

  .sro-form {
    float: left;
  }

  .daily-activity-header {
    margin-bottom: 20px;
    height: 50px;

    .date-picker-top {
      float: left;
    }

    .agent-selector {
      float: left;
      margin-left: 20px;
      background-color: transparent;

      .selected-item {
        background-color: transparent;
      }
    }

    div[class*='MuiSelect'] {
      padding: 12px 0 0 12px;
      border-radius: 4px;
      border: 1px solid $color-b8c1c8;
    }

    div[class*='MuiSelect']:focus {
      background-color: white;
    }
  }

  div [class*='MuiCardContent'] {
    padding: 0;
  }

  table.daily-activity-agent {
    border-spacing: 0;
    width: 100%;
  }

  .agent-summary-box {
    background-color: white;
    height: 120px;
  }

  .agent-profile {
    margin-top: 33px;
    margin-left: 20px;

    .agent-title {
      font-size: 14px;
      letter-spacing: -0.09px;
      color: $color-8f9bad;
    }
  }

  .nested-pie {
    margin: 20px 0 0 30px;
  }

  .total-order-box {
    .agent-total-orders-value {
      margin-top: 33px;

      font-size: 30px;
      font-weight: bold;
      line-height: 0.93;
      color: $color-7ed321;
    }

    .agent-total-orders-title {
      margin-top: 10px;
      font-size: 12px;
      color: $color-354052;
    }
  }

  .agent-summary-pair-line {
    clear: both;
    width: 200px;

    &.crowded {
      width: 290px;
      .agent-summary-key {
        width: auto;
      }

      .agent-summary-value {
        margin: 0 10px 0 4px;
      }
    }

    &.route-link {
      margin-top: 40px;
      display: block;
      text-align: left;
    }
  }

  .pair-column {
    padding-top: 38px;
    width: 160px;
  }

  .agent-image {
    margin-bottom: 10px;
  }

  .agent-summary-key {
    float: left;
    width: 85px;
    font-size: 12px;
    color: $offgreen;
    line-height: 25px;
    text-align: left;
  }

  .agent-summary-value {
    float: left;
    margin-left: 10px;
    font-size: 18px;
    color: $gray5;
  }

  tr.detail-header {
    font-size: 13px;
    color: $navy;
    background-color: $super-lightgray;
    text-align: center;

    th {
      height: 29px;
    }
  }

  tr.details {
    background-color: white;
    height: 45px;

    &:hover {
      background-color: $fb-lightgray;
    }

    &.canceled,
    &.canceled:hover {
      background-color: $offwhite;
    }
  }

  /*
        agent boxes:
        round corners
        add shadow

        table: fixes size

        light orange and light blue bg for the graph
    */

  td {
    font-size: 13px;
    color: $green-gray;
    min-width: 20px;

    &.rating {
      font-size: 14px;
      color: $orange;
    }

    &.leftmost {
      border-left: 4px white solid;
      padding-left: 10px;
    }

    &.centered {
      text-align: center;
    }
  }

  .visit-type-icon {
    height: 23px;
  }

  // Colors by classification rules

  tr.canceled .leftmost {
    border-color: $shiny-orange;
  }

  tr.closed {
    .leftmost {
      border-color: $shiny-green;
    }

    .time {
      font-weight: bold;
      color: $shiny-green;
    }
  }

  tr.open .leftmost {
    border-color: $blue;
  }

  .sro-tabset {
    background: $white;
    margin-top: 0px;

    .sro-tabset-tab {
      padding-top: 10px;
    }

    .tab-headers {
      text-align: left;

      .tab-header {
        margin-right: 30px;
        padding-bottom: 5px;

        &.active {
          color: $color-2888d0;
        }
      }
    }
  }
}

.daily-activity-placeholder {
  .summary-boxes {
    height: 178px;
    clear: both;
  }

  .agent-boxes {
    margin-top: 28px;
  }

  .shadow-summary-box {
    position: relative;
    float: left;
    margin-right: 15px;

    width: 341px;
    height: 178px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    .line-box {
      position: absolute;
      top: 30px;
      left: 40px;

      .shadow-summary-line {
        margin-top: 8px;
        width: 107px;
        height: 9px;
        background-color: $super-lightgray;
      }
    }

    .shadow-summary-circle {
      position: absolute;
      top: 40px;
      left: 190px;

      width: 110px;
      height: 110px;
      border-radius: 110px;
      background-color: $super-lightgray;
    }
  }

  .shadow-agent-box {
    position: relative;
    margin-top: 12px;
    width: 1049px;
    height: 120px;
    border: solid 1px $color-e9eff4;
    background-color: $white;
  }

  .shadow-agent-picture {
    height: 45px;
    width: 45px;
    border-radius: 45px;

    position: absolute;
    top: 15px;
    left: 50px;
    background-color: $super-lightgray;
  }

  .shadow-agent-title {
    background-color: $super-lightgray;
    position: absolute;
    top: 80px;
    left: 40px;
    height: 4px;
    width: 67px;
  }

  .shadow-agent-line {
    width: 803px;
    height: 9px;
    background-color: $super-lightgray;

    position: absolute;
    top: 45px;
    left: 200px;

    &.line-1 {
      width: 254px;
    }

    &.line-2 {
      top: 65px;
    }

    &.line-3 {
      top: 85px;
    }
  }
}
