@import '../colors.scss';

.user-form {
  .edit-table > tbody > tr > td {
    vertical-align: top;
    width: 256px;
    padding-right: 30px;
  }

  &.user-create {
    .simple-form > div[class*='MuiCardContentroot'] {
      margin-top: 80px;
    }
  }

  div[class*='MuiToolbarroot'][role='toolbar'] {
    background: none;
    margin: 20px 0 15px 0;
  }
}

.user-page {
  div[class*='MuiToolbarregular'] {
    height: 80px;
  }
}

.user-form-wrapper {
  margin-left: 20px;
}
