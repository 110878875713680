@import '../../colors.scss';

.client-rating-form.sro-form {
  padding: 30px;

  .add-rating {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: $color-408aff;
    padding-left: 30px;

    .plusInCircle {
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
    }
  }

  .xInCircle {
    position: absolute;
    top: 34px;
    right: -5px;
  }

  .upper-title {
    margin: 0;
  }

  .sro-input {
    margin-right: 48px;
    margin-bottom: 16px;

    &.value-field {
      margin-right: 0;
    }
  }

  .sro-dropdown {
    width: 237px;
  }

  .rating-buttons {
    text-align: right;
    padding: 10px 20px;
    height: 25px;

    .rating-button {
      margin-right: 20px;
      display: inline-block;
      cursor: pointer;
      width: 103px;
      height: 17px;
      font-size: 14px;
      color: $color-408aff;
      text-transform: none;
    }
  }

  .rating-box {
    position: relative;
    margin-top: 14px;

    .rating-line {
      position: relative;
      padding-top: 20px;

      .delimiter-line {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 857px;
        height: 0;
        border-bottom: solid 1px #e5e9ec;
      }
    }

    .reorder-buttons-wrapper {
      position: absolute;
      top: 50%;
      left: -25px;

      .reorder-buttons {
        $height: 45px;
        position: absolute;
        top: -$height / 2;
        left: 0;
        height: $height;
      }

      .move-button {
        cursor: pointer;
        color: $color-c3c2c2;

        &.disabled {
          color: $color-ebeff4;
          cursor: default;
        }
      }
    }
  }

  .rating-list {
    position: relative;
  }

  .condition-line {
    position: relative;
    margin-top: 12.5px;

    .sro-dropdown {
      margin-right: 34px;
      float: left;
    }
  }
}
