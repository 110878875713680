@import '../../colors.scss';

$paddingRight: 4px;
$paddingLeft: 8px;
$paddingTop: 8px;
$paddingBottom: 4px;

.layer-button-wrapper {
    position: relative;

    .layer-selection-popup {
      $width: 150px;

      position: absolute;
      top: -25px;
      left: -$width;

      width: $width;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

      .layer-list {
        padding: $paddingTop $paddingRight $paddingBottom $paddingLeft;
        width: calc(100% - #{$paddingRight} + #{$paddingLeft});
        height: 100%;
        background-color: rgba(57, 57, 57, 0.88);
        border-radius: 4px;
      }

      .layer {
        .layer-checkbox, .layer-name {
          display: inline-block;
        }

        .layer-name {
          margin-left: 6px;
          font-size: 10px;
          font-weight: bold;
          color: $white;

          line-height: 1;
          vertical-align: middle;
          height: 20px;
        }
      }

      .popup-triangle-wrapper {
        position: absolute;
        top: calc(50% - #{($paddingTop + $paddingBottom) / 2});
        right: -10px;
        width: 10px;
        height: 25px;
        overflow: hidden;

        .popup-triangle {
          position: absolute;
          top: 5px;
          left: -7px;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
        }
      }

      .layer-list, .popup-triangle {
        opacity: 0.9;
        background-color: rgba(57, 57, 57, 0.88);
      }
    }
}
